<template>
  <div class="procedencias-actas pl-4">
       <div class="procedencias mb-3">
      <div class="proceso float-left mr-3 mb-3">
        <font-awesome-icon
          :icon="['far', 'file']"
          class="fa-2x mr-2"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="hourglass"
          class="fa-2x mr-2"
        ></font-awesome-icon>
        Acta en proceso
      </div>
      <template v-if="!extranjero">
        <div class="terminado float-left mr-3 mb-3">
          <font-awesome-icon
            :icon="['far', 'file']"
            class="fa-2x mr-2"
          ></font-awesome-icon>
         
          Acta Voto Anticipado
        </div>
        
      </template>
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcedenciaActa',
  computed: {
    extranjero() {
      return this.$route.name == "VotoEnElExtranjero" ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.procedencias {
  height: 35px;
  display: block;
  padding-top: 7px;

  .proceso {
    color: #858585;
  }
  .terminado {
    color: #6F65A3;
  }
}
</style>