<template>
  <Layout>
    <!-- Botón de regresar -->
    <div v-if="eleccion=='D'">
      <router-link
            class="btn btn-primary btn-regresar text-center d-block mt-3 mx-auto w-50 mb-4"
            to="/D/ENT/VPC"
      >
        Regresar
      </router-link>      
    </div>
    <div v-if="eleccion=='A'">
      <router-link
            class="btn btn-primary btn-regresar text-center d-block mt-3 mx-auto w-50 mb-4"
            to="/A/ENT/VPC"
      >
        Regresar 
      </router-link>      
    </div>


    <!-- Información general -->
    <div class=" mt-5">        
          <div class="titulos">
            <h2>{{ titulo }} - 
              <span>
                <strong v-if="eleccion=='Diputaciones' ">Detalle del Distrito</strong> 
                <strong v-else="eleccion=='Diputaciones' " class="mr-2">Detalle del Municipio</strong> 
                - Voto Anticipado
              </span>
            </h2>
            <h4>
              <div class="menu-migajas mt-3">
                  <strong>
                  <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`" class="estado-volver">
                    Baja California Sur
                  </router-link>
                </strong>
                /
                <span v-if="this.$route.params.eleccion !== 'A'">
                  Distrito {{distrito}}. {{ distrito_nombre }}
                </span>
                <span v-else>
                  Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
                </span> /
                <span>
                  Voto Anticipado
                </span>
              </div>
            </h4>
            <div class="mt-3">
              <p class="text-justify">
                El total de votos calculado y porcentaje que se muestran se refiere a los Votos
                asentados en las Actas de Escrutinio y Cómputo del Voto Anticipado hasta el momento.
                <br> <br>
                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.
                No obstante, al considerar todos los decimales, suman 100%
              </p>
            </div>
            
            <div class="mt-3">
              <h3>Votos en Actas Contabilizadas</h3>
            </div>            
          </div>        

    </div>
    <!--Resumen Votación -->
    <div class="resumenDeLaVotacion mb-5">
          <div class="">
            <p v-if="eleccion === 'D'">
              Por presentación, los decimales de los porcentajes muestran sólo cuatro digitos. No obstante, al considera todos los decimales, suman 100%. 
            </p>
            <h3 class="">
              Resumen de la votación
            </h3> 
          </div>       
          
          <div class="borde">
              <div class="d-flex ">
              <div class="icono pt-4 mr-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
              texto="Votos acumulados"
              :numero="resumenDistrito.total_votos_acumulados_numero"
              :porcentaje="resumenDistrito.total_votos_acumulados_porcentaje"
            />
            <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoAcumulado"
                  />
            </span>
                  <ModalBaseMovil :textoModal="votoAcumulado"  ref="modalRefVotoAcumulado" />             

            </div>
            <div class="d-flex ">
              <div class="icono-suma pt-4 mt-4 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
                texto="Candidaturas no registradas"
                :numero="resumenDistrito.total_votos_candidatura_noregistrada_numero"
                :porcentaje="resumenDistrito.total_votos_candidatura_noregistrada_porcentaje"
              />
            </div>

            <div class="d-flex">
              <div class="icono-suma pt-4 mt-3 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
            
              
                <ResumenVotacion
                texto="Votos Nulos"              
                :numero="resumenDistrito.total_votos_nulos_numero"
                :porcentaje="resumenDistrito.total_votos_nulos_porcentaje"
                
                />
                <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoNulo"
                  />
                </span>
                  <ModalBaseMovil :textoModal="votoNulo"  ref="modalRefVotoNulo" />

              
            </div>
          </div>
          <div class="total-votos mt-5">
           <div class="d-flex justify-content-center">
            <h5 class="text-center mr-2 mt-2">Total de votos</h5>
            
                <span id="tooltip" class="info-votos">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg "
                    @click="mostrarModalTotalVotos"
                    />
                     
                </span> 
                <ModalBaseMovil :textoModal="totalVotos" v-if="esVotototal" ref="modalRefVotosTotales" />

               

           </div>
            <div class="d-flex">
              <div class="icono-suma ml-4 mt-2 pt-1">
                      <font-awesome-icon icon="equals" class="fa-2x" />
              </div>
              <div class="ml-3 mt-2 t">
                <h2 >{{ sumaTotal || 0}} </h2>
              </div>
            </div>
            <div class="text-center ">
              <h4>100.000%</h4>
            </div>
          </div>
        
    </div>
    <!-- Tabla de Estadistica de Mesas de Escrutinio -->
    <div class="table-responsive2 votos">
        <div class="votos-cabecera">
          <div class="titulos mt-3">
           <div  class=" pt-4 text-justify">
              <h3 class="mb-5"> Estadística </h3>
              <div class="ml-3 mr-3 mb-4">

                <strong >
                  Participación ciudadana con base en la Lista Nominal de las mesas con Actas PREP Contabilizadas
                </strong>
              </div>
              <!-- <EstadisticasInternas :detalle="estadisticas" /> -->
           </div>
           <!-- Tabla con progresbar -->
           
            <div
              v-for="(casilla, index) in estadisticas.datos_casillas"
              class="votosTotalesAvance mb-4"
              :key="index"
            >
              <div class="grilla mb-2">
                <div class="leyenda">
                  <p class="font-weight-bold mb-0">
                    {{ casilla.casilla_nombre }}
                  </p>
                </div>
                <div class="total">
                  <h4 class="mb-0 text-right">{{ casilla.participacion_ciudadana_porcentaje }}%</h4>
                </div>
              </div>
              <b-progress max="100" :value="casilla.participacion_ciudadana_porcentaje" />
            </div>
          
           <!-- Observaciones en Actas -->
           <div class="row ObservacionesActas py-3">
              <div class="col-12">
                <h5 class="font-weight-bold pt-3 mb-3 d-lg-none">
                  Observaciones en Acta
                </h5>                

                <div class="grilla">
                  <div class="pt-4">
                    <p class=" titulo-uno">
                      <b>
                        Contabilizadas
                      </b>
                    </p>

                    <p class="font-weight-bold mb-0 small">
                      Ilegible
                    </p>
                    <p class="small text-justify">
                      La cantidad de votos en algún campo del Acta PREP
                      no puede leerse, tanto en letra como en número.
                    </p>

                    <p class="font-weight-bold mb-0 small">
                      Sin dato
                    </p>
                    <p class="small text-justify">
                      La cantidad de votos no fue asentada en el Acta PREP
                      ni en letra ni en número en algún campo del Acta PREP.
                    </p>

                    <p class="font-weight-bold mb-0 small">
                      Identificación por Proceso Técnico Operativo
                    </p>
                    <p class="small text-justify">
                      Los datos del Acta PREP no se identificaron y se solicitó
                      verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
                    </p>

                    <div style="margin-top: 3rem;" v-if="anchoPantalla >= 992">
                      <p class="titulo titulo-tres">
                        <b>
                          No identificadas - <small>Fuera del catálogo</small>
                        </b>
                      </p>

                      <p class="small">
                        No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas
                        aprobadas por el INE, al presentar alguna comisión, ilegibilidad o error en
                        sus datos de identificación. Para fines de publicación, el Acta se muestra al
                        final del listado de Actas aprobadas en la Base de Datos.
                      </p>
                    </div>
                  </div>

                  <div>
                    <p class="titulo titulo-dos">
                      <b>
                        No Contabilizadas
                      </b>
                    </p>

                    <p class="font-weight-bold mb-0 small">
                      Excede Lista Nominal
                    </p>
                    <p class="small text-justify">
                      La suma de todos los votos en el Acta PREP, excede el número de ciudadanas
                      y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el
                      número de votos emitidos por los representantes de los Partidos Políticos y,
                      en su caso Candidaturas Independientes.
                    </p>

                    <p class="font-weight-bold mb-0 small">
                      Sin acta
                    </p>
                    <p class="small text-justify">
                      El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio
                      y Transmisión de Datos (CATD), por algunas de las siguientes causas:
                    </p>

                    <ol class="small pl-5">
                      <li>Sin Acta por paquete no entregado.</li>
                      <li>Sin Acta por casilla no instalada.</li>
                      <li>Sin Acta por paquete entregado sin Bolsa PREP.</li>
                    </ol>

                    <p class="font-weight-bold mb-0 small">
                      Todos los campos son ilegibles
                    </p>
                    <p class="small text-justify">
                      Todos los campos del Acta PREP correspondientes al número de votos,
                      son ilegibles tanto en letra como en número.
                    </p>

                    <p class="font-weight-bold mb-0 small">
                      Todos los campos vacíos
                    </p>
                    <p class="small text-justify">
                      Todos los campos del Acta PREP correspondientes al número de votos,
                      se encuentran vacíos tanto en letra como en número.
                    </p>
                  </div>

                  <div v-if="anchoPantalla < 992">
                    <p class="titulo titulo-tres">
                      <b>
                        No identificadas - <small>Fuera del catálogo</small>
                      </b>
                    </p>

                    <p class="small">
                      No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas
                      aprobadas por el INE, al presentar alguna comisión, ilegibilidad o error en
                      sus datos de identificación. Para fines de publicación, el Acta se muestra al
                      final del listado de Actas aprobadas en la Base de Datos.
                    </p>
                  </div>
                </div>
              </div>
          </div>

          </div>
        </div>
      </div>



  </Layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import ConocePorcentajeParticipacionCiudadana from '@/components/ConocePorcentajeParticipacionCiudadana.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import DistribucionVotos from '@/components/Movil/componentes/DistribucionVotos.vue';
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';
import DetalleEleccion from '@/components/DetalleEleccionCandidaturas.vue';
import ModalBaseMovil from '@/components/Movil/componentes/ModalBase.vue';
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'VotoAnticipadoMovil',
  components: {
    Layout,
    ResumenVotacion,
    DistribucionVotos,
    ProgresoVertical,
    DetalleEleccion,
    ConocePorcentajeParticipacionCiudadana,
    ModalBaseMovil,

  },
  data() {
    return {
      random: 0,
      show: false,
      esVotototal: true,
      votoAcumulado:
      "Es la suma de votos obtenidos por Partido Político, Coalición y Candidatura Independiente, sin incluir los votos de candidaturas no registradas ni nulos.",
      totalVotos:
      "Se refiere a la suma total de votos que realiza el sistema informático con base en los datos que muestran en cada Acta; podrás consultar la cifra del total de votos mostrados en cada Acta en la base de datos de PREP (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).",
      votoNulo: 
      "El total y el porcentaje de votos para candidaturas no registradas y nulos, considera la votación en Casillas y Anticipados." ,
    
    };
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        (d) => d.id === this.distrito,
      ).descripcion;
    },
    titulo() {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura'; }
      if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento'; } return 'Diputaciones';
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    distrito() {
      return this.$route.params.distrito;
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    votosXCandidatoDistritoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXCandidatoDistritoTarjeta.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXDistritoTarjeta.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aVotosXCandidatoDistritoTarjeta.municipios[
        this.$route.params.distrito - 1
      ];
    },
    resumenDistrito() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return this.$store.state.aResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
    },
    distribucion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gDistribucionVotosCandidaturasDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dDistribucionVotosCandidaturasDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      }
      return this.$store.state.aDistribucionVotosCandidaturasDistrito.municipios[
        this.$route.params.distrito - 1
      ];
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelDistrito.municipios[
          this.$route.params.distrito - 1
        ];
      }

      return {};
    },
    listaSecciones() {
      if (this.eleccion !== 'A') {
        return this.$store.state.elementosGenerales.datos_secciones
          .distritos[this.distrito - 1]
          .datos_secciones;
      }
      return this.$store.state.elementosGenerales
        .datos_secciones_por_municipio[this.distrito - 1]
        .datos_secciones;
    },
  },
  // mounted(){
  //   this.calcularSumaTotal();
  // },
  methods: {
  //   calcularSumaTotal() {
  //   const sumaAcumulados = parseInt(this.resumenDistrito.total_votos_acumulados_numero, 10) || 0;
  //   const sumaNoRegistrados = parseInt(this.resumenDistrito.total_votos_candidatura_noregistrada_numero, 10) || 0;
  //   const sumaNulos = parseInt(this.resumenDistrito.total_votos_nulos_numero, 10) || 0;
    
  //   this.sumaTotal = sumaAcumulados + sumaNoRegistrados + sumaNulos;
  // },
    actualizarDetalleEleccion() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    obtenerClaseGanador(candidato) {
      const votos = this.votosXCandidatoDistritoTarjeta;
      return candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
        ? 'ganador'
        : '';
    },
    calcularAvance(avance) {
      return Math.ceil(parseFloat(avance));
    },
    mostrarModalVotoNulo() {
      this.$refs.modalRefVotoNulo.show();

    },
    mostrarModalVotoAcumulado() {
      this.$refs.modalRefVotoAcumulado.show();

    },
    mostrarModalTotalVotos() {
      this.$refs.modalRefVotosTotales.show();
    },
  },
  
};
</script>

<style lang="scss" scoped>
.titulo-uno{
  color: #4099A0;
  border-left: 8px solid #4099A0;
    b{
      font-size: 25px;
      padding-left: 10px;
    }
}
.titulo-dos{
  color: #D1117E;
  border-left: 8px solid #D1117E;
    b{
      font-size: 25px;
      padding-left: 10px;
    }
}
.ObservacionesActas{
  background-color: #F5F5F5;
}
.borde{
  border: 1px solid #ddd;
  border-spacing:5px;
}

.icono {
  background-color: #d3097f00;
  svg {
    color: #d3097f00;
  }
}	

.titulos{
  h2{
    font-size: 21px;
    span{
      color: #582D73;
    }    
  }
  h3 {
        font-size: 20px;
        color: black;
        font-weight: bold;
        border-left: 10px solid #582D73;
        padding-left: 10px;
  }
  p{
    font-size: 14px;
  
  }
}
.menu-migajas{
  font-size: 16px;
}
.btn-regresar{
  background-color: #C791A8 ;
  border: none;
}
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}

.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-height: 50px;
        display: block;
        margin: 0 auto;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}

.btn-link {
  border-radius: 15px;
  background-color: #582D73;
  border-color: transparent;
}
</style>
