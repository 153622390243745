<template>
  <div>
    <VotoAnticipadoEscritorio v-if="anchoPantalla >= 992" />
    <VotoAnticipadoMovil v-else />
  </div>
</template>

<script>
import VotoAnticipadoEscritorio from '@/components/Escritorio/vistas/VotosPorCandidatura/VotoAnticipado.vue'
import VotoAnticipadoMovil from '@/components/Movil/vistas/VotosPorCandidaturas/VotoAnticipado.vue'

export default {
  name: 'VotoAnticipado',
  components: {
    VotoAnticipadoEscritorio,
    VotoAnticipadoMovil,
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024 - Base de datos'
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
}
</script>
