<template>
  <div class="vista-escritorio">
    <Cabecera/>
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>{{ eleccion }} - <span>
              <!-- <strong v-if="this.$route.params.eleccion !== 'A'">Detalle del Distrito</strong> -->
              <strong >Detalle del Distrito </strong>

              <!-- <strong v-else>Detalle por Municipio</strong> -->
              <!-- <strong v-else>Detalle del Distrito</strong> -->

              - Voto Anticipado  </span></h2>
            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`" class="estado-volver">
                  Baja California Sur
                </router-link>
              </strong>
              /
              <span v-if="this.$route.params.eleccion !== 'A'">
                Distrito {{distrito}}. {{ distrito_nombre }}
              </span>
              <span v-else>
                Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
              </span> /
              <span>
                Voto Anticipado 
              </span>
            </h4>
        
            <div class="mt-5">
              <h3>Votos en Actas Contabilizadas</h3>

            </div>
          
           
           

            <div class="row px-3">
            <!-- Insertar tarjetas -->

            <div v-if="eleccion === 'Diputaciones'">
              <div v-if="listaDistritos=='1'">
                <!-- <slider-votos :votos="votosXCandidatoDistritoTarjetaVA" /> -->
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                  <!-- <div class="rowFlex" id="row-flex">
                    <div
                      v-for="(candidato, index) in votos.datos_candidaturas || votos.datos_votos.slice(2,7)"
                      :key="index"
                      style="padding:10px;"
                      :class="candidato.candidatura_id === votos.candidato_id_con_mayor_numero_votos || candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                              ? 'resumen-voto-candidatura2 '
                              : ''"
                    >
                      <div class="resumen-voto-candidatura ">
                      
                      <div class="contorno">

                        <div class="grilla-datos ">
                          <div class="grilla-datos-candidatura">
                            
                            <div  class="foto" style="text-align: center">
                              <img
                                :src="candidato.candidatura_logo"
                                :alt="candidato.candidatura_nombre"
                                style="max-height: 40px"
                              />
                            </div>
                          <div class="nombre text-center mb-4">
                          </div>
                          </div>
                        </div>
                    
                        <div class="grilla-resumen">
                          <div class="total text-left">
                            <p class="mb-0 font-weight-bold">
                              Votos <br />
                              <span v-if="candidato.candidatura_total_votos_numero">
                                {{ candidato.candidatura_total_votos_numero }}
                              </span>
                              <span v-else>
                                {{ candidato.candidatura_votos_cantidad }}
                              </span>
                            </p>
                          </div>
                          <div class="porcentaje text-righd">
                            <p class="mb-0 font-weight-bold">
                              <span v-if="candidato.candidatura_total_votos_porcentaje">
                                {{ candidato.candidatura_total_votos_porcentaje }}%
                              </span>
                              <span v-else>
                                {{ candidato.candidatura_votos_porcentaje }}%
                              </span>
                            </p>
                          </div>
                        </div>
                        
                      </div>

                      </div>
                    </div>
                  </div> -->

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='2'">
                <!-- <slider-votos :votos="votosXCandidatoDistritoTarjetaVA" /> -->
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>                  

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='3'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='4'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='5'">
                <!-- <slider-votos :votos="votosXCandidatoDistritoTarjetaVA" /> -->
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>                  

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='6'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='7'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='11'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='12'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='14'">
                <!-- <slider-votos :votos="votosXCandidatoDistritoTarjetaVA" /> -->
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>                  

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='15'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="pes" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="listaDistritos=='16'">
                <!-- <slider-votos :votos="votosXCandidatoDistritoTarjetaVA" /> -->
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>                  

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>
            </div>
            
            <div v-else>
              <div v-if="ayuntamiento.municipio_id =='2'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>   

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="ayuntamiento.municipio_id =='3'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>                  

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

              <div v-if="ayuntamiento.municipio_id =='4'">
                <div class="contenedor-padre">
                  <button
                    class="btn-fijo btn-izquierda"
                    @click="scrollIzquierda"
                    :disabled="horizontalScrollValue === 0"
                  >
                    <font-awesome-icon icon="chevron-left" class="fa-3x" />
                  </button>
                  <div  class=" contorno rowFlex" id="row-flex" >
                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc1" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class=" pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="cc2" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="mc" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="fxm" alt="" style="width: 90px;height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div  style="padding:10px;">
                      <div class="resumen-voto-candidatura pl-3"  >
                        <div>
                          <img :src="ml" alt="" style="width: 90px; height: auto;padding-left: 20px; " />
                        </div>
                        <div class="pt-3 contorno2 d-flex justify-content-between">
                          <div class="mb-0 pb-0">
                            <a>Votos</a>
                            <p>0</p>
                          </div>
                          <div class="pl-3 ">
                            <a class="pr-3">Porcentaje</a>
                            <p>0.0000%</p>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                  

                  </div>
                

                  <button
                    class="btn-fijo btn-derecha"
                    @click="scrollDerecha"
                    :disabled="horizontalScrollValue > maximoLogitudScroll"
                  >
                    <font-awesome-icon icon="chevron-right" class="fa-3x" />
                  </button>
                </div>

              </div>

             
            </div>

            <!-- Resumen Listo -->
              <div class="col-8 mb-3 pl-5 resumen-votacion">
                <h4 class="mb-2">
                  Resumen de la votación
                </h4>
                <div v-if="eleccion === 'Diputaciones'">
                  
                  <div  class="grilla-suma"  v-if="listaDistritos=='1'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      8
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>8</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='2'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      5
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>5</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='3'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      2
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>2</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='4'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                     2
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>2</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='5'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      3
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>3</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='6'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      4
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>4</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='7'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      1
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>1</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='11'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      1
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>1</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='12'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      2
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>2</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='14'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      3
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>3</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='15'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      3
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>3</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="listaDistritos=='16'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados 
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      1
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>1</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>

                </div>

                <div v-else>                  
                  <div  class="grilla-suma"  v-if="ayuntamiento.municipio_id =='2'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      3
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>3</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="ayuntamiento.municipio_id =='3'">
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados 
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      19
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>19</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                  <div  class="grilla-suma"  v-if="ayuntamiento.municipio_id =='4'" >
                    <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos  acumulados 
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      <!-- {{ resumenDistrito.total_votos_acumulados_numero }} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_acumulados_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br /> -->
                      13
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos  Nulos 
                    
                     <br />
                      <!-- {{ resumenDistrito.total_votos_nulos_numero}} <br /> -->
                      0
                      <br>
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_nulos_porcentaje }}% -->
                        0.0000%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right mr-2">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext p-2">
                          Se refiere a la suma total de votos que realiza el sistema informático con 
                          base en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de votos mostrados en cada Acta en la base de datos de PREP 
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      <br />
                      <big>13</big>
                      <br>
                      <!-- <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br /> -->
                      <span class="porcentaje">
                        <!-- {{ resumenDistrito.total_votos_general_porcentaje }}% -->
                        100.0000%
                      </span>
                    </p>
                  </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tabla de detalle de votos x Mesa de Escrutinio -->   
      
      

        <div class="table-responsive ">
        <div class="votos-cabecera votos">
          <div class="titulos mt-3">
            <h3> 
              Detalle por Actas de Mesa de Escritinio y Cómputo del Voto Anticipado         
            </h3>
         
           <!-- Tabla lista -->
            <div class=" tabla table my-4 ml-2">
              <div class="  table-striped">
              <table v-if="eleccion === 'Diputaciones'">
               <div  v-if="listaDistritos=='1'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC1" :key="index">                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado
                      </td>
                      <!-- AQUI VA EL ICONO PARA EL ACTA -->
                      <td>
                       
                        <a :href="DD1" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>

                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        8
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        8
                      </td>
                      <!-- Observaciones -->
                      <td>


                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='2'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC2" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado
                      </td>
                      <td>
                        <a :href="DD2" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        5
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        5
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='3'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC3" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD3" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        2
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        2
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='4'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC4" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD4" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        2
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        2
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='5'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC5" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD5" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      
                      <!-- Candidaturas no registradas -->
                      <td>
                        3
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        3
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='6'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC6" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD6" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        4
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        4
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='7'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC7" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD7" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        1
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        1
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='11'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC11" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD11" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        1
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        1
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='12'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC1" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD12" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        2
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        2
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='14'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC14" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD14" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        3
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        3
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='15'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC1" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD15" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        3
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                       3
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="listaDistritos=='16'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC1" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="DD16" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        1
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        1
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
              </table>

              <table v-else>
               <div  v-if="ayuntamiento.municipio_id =='2'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in MC2" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <!-- AQUI VA EL ICONO PARA EL ACTA -->
                      <td>
                        <a :href="M2" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        3
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        0
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        3
                      </td>
                      
                      <!-- Observaciones -->
                      <td>


                      </td>
                    </tr>
                    
              
                </tbody>
               </div>
               <div  v-if="ayuntamiento.municipio_id =='3'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in DC2" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="M3A1" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        5
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        5
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 2
                      </td>
                      <td>
                        <a :href="M3A2" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        2
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        2
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 3
                      </td>
                      <td>
                        <a :href="M3A3" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        2
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        2
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Voto Anticipado 4
                      </td>
                      <td>
                        <a :href="M3A4" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        3
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        3
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Voto Anticipado 5
                      </td>
                      <td>
                        <a :href="M3A5" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        4
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        4
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Voto Anticipado 6
                      </td>
                      <td>
                        <a :href="M3A6" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        3
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        3
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                </tbody>
               </div>
               <div  v-if="ayuntamiento.municipio_id =='4'">
                <thead >
                  <tr class="text-center">
                    <th>
                      Mesa de Escrutinio  
                    </th>
                    <th>
                     Acta digitalizada
                    
                    </th>
                    <!-- Iconos de los partidos -->
                    <th v-for="(image, index) in MC4" :key="index">
                      
                      <img :src="image.src" :alt="image.alt" style="width: 40px; height: auto;" />
                      
                    </th>
                    
                    <th>
                      Candidaturas no registradas    
                    </th>
                    <th>
                      Votos nulos
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Observaciones en Acta
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 1
                      </td>
                      <td>
                        <a :href="M4A1" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        8
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        8
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
                    
                    <tr class="text-center">
                      <td>
                        Voto Anticipado 2
                      </td>
                      <td>
                        <a :href="M4A2" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        1
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        1
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Voto Anticipado 3
                      </td>
                      <td>
                        <a :href="M4A3" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        1
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        1
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Voto Anticipado 4
                      </td>
                      <td>
                        <a :href="M4A4" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        2
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        2
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>

                    <tr class="text-center">
                      <td>
                        Voto Anticipado 5
                      </td>
                      <td>
                        <a :href="M4A5" target="_blank">
                          <font-awesome-icon
                            :icon="['far', 'file']"
                            class="mr-2"
                          ></font-awesome-icon>
                        </a>
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <td>
                        0
                      </td>
                      <!-- Candidaturas no registradas -->
                      <td>
                        1
                      </td>
                      <!-- Votos Nulos -->
                      <td>
                        0
                      </td>
                      <!-- Total -->
                      <td>
                        1
                      </td>
                      <!-- Observaciones -->
                      <td>
                      </td>
                    </tr>
              
                </tbody>
               </div>
                                    
              </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Tabla de votos x Mesa de Escrutinio -->
     
      <div class="table-responsive4 votos">
        <div class="votos-cabecera">
          <div class="titulos mt-3">
            <h3> 
              Estadística por Actas de Mesa de Escrutinio y Cómputo del Voto Anticipado          
            </h3>
         
           <!-- Tabla para las estadisticas por Actas -->
            <div class=" tabla table my-4 ml-2">
              <div class="  table-striped">
                <table v-if="eleccion === 'Diputaciones'">
                  <div  v-if="listaDistritos=='1'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          8
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          8
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='2'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          5
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          5
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='3'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='4'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='5'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                         3
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='6'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          4
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          4
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='7'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='11'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='12'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='14'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='15'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="listaDistritos=='16'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                </table>

                <table v-else>
                  <div  v-if="ayuntamiento.municipio_id =='2'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                  
                    </tbody>
                  </div>
                  <div  v-if="ayuntamiento.municipio_id =='3'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                      
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          5
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          5
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 2
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 3
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 4
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 5
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          4
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          4
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 6
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          3
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                  
                    </tbody>
                  </div>
                  <div  v-if="ayuntamiento.municipio_id =='4'">
                    <thead >
                      <tr class="text-center">
                        <th>
                          Mesa de Escrutinio 
                        </th>
                        <th>
                        Total de Votos
                        <span id="tooltip2">
                            <font-awesome-icon
                              icon="info-circle"
                              class="fa-sm fa-info"
                            />
                            <span class="tooltiptext p-2">
                              Se refiere a la suma total de votos que realiza el sistema informático con 
                              base en los datos que muestran en cada Acta; podrás consultar la cifra 
                              del total de votos mostrados en cada Acta en la base de datos de PREP 
                              (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                            </span>
                          </span>
                        </th>
                        
                        <th>
                        Lista Nominal
        
                        </th>
                        <th>
                          Participación ciudadana
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 1
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          8
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          8
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                      
                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 2
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 3
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 4
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          2
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>

                      <tr  class="text-center"                    
                    >
                        <td>
                          Voto Anticipado 5
                          <!-- {{ acta.mesaEscru }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.votos_total }} -->
                        </td>
                        <td>
                          1
                          <!-- {{ acta.listaNominal }} -->
                        </td>
                        <td>
                        100.0000%
                          <!-- {{ acta.participacion_ciudadana }} -->
                        </td>
                      
                      </tr>
                  
                    </tbody>
                  </div>
                  
               
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Tabla de informacion -->
      <div class="row ObservacionesActas py-3">
        <div class="col-12">
          <h5 class="font-weight-bold mb-3 d-lg-none">
            Observaciones en Acta
          </h5>

          <h3 class="font-weight-bold mb-5 d-lg-block d-none">
            Observaciones en Acta
          </h3>

          <div class="grilla">
            <div>
              <p class="titulo titulo-uno">
                <b>
                  Contabilizadas
                </b>
              </p>

              <p class="font-weight-bold mb-0 small">
                Ilegible
              </p>
              <p class="small">
                La cantidad de votos en algún campo del Acta PREP
                no puede leerse, tanto en letra como en número.
              </p>

              <p class="font-weight-bold mb-0 small">
                Sin dato
              </p>
              <p class="small">
                La cantidad de votos no fue asentada en el Acta PREP
                ni en letra ni en número en algún campo del Acta PREP.
              </p>

              <p class="font-weight-bold mb-0 small">
                Identificación por Proceso Técnico Operativo
              </p>
              <p class="small">
                Los datos del Acta PREP no se identificaron y se solicitó
                verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
              </p>

              <div style="margin-top: 3rem;" v-if="anchoPantalla >= 992">
                <p class="titulo titulo-tres">
                  <b>
                    No identificadas - <small>Fuera del catálogo</small>
                  </b>
                </p>

                <p class="small">
                  No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas
                  aprobadas por el INE, al presentar alguna comisión, ilegibilidad o error en
                  sus datos de identificación. Para fines de publicación, el Acta se muestra al
                  final del listado de Actas aprobadas en la Base de Datos.
                </p>
              </div>
            </div>

            <div>
              <p class="titulo titulo-dos">
                <b>
                  No Contabilizadas
                </b>
              </p>

              <p class="font-weight-bold mb-0 small">
                Excede Lista Nominal
              </p>
              <p class="small">
                La suma de todos los votos en el Acta PREP, excede el número de ciudadanas
                y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el
                número de votos emitidos por los representantes de los Partidos Políticos y,
                en su caso Candidaturas Independientes.
              </p>

              <p class="font-weight-bold mb-0 small">
                Sin acta
              </p>
              <p class="small">
                El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio
                y Transmisión de Datos (CATD), por algunas de las siguientes causas:
              </p>

              <ol class="small">
                <li>Sin Acta por paquete no entregado.</li>
                <li>Sin Acta por casilla no instalada.</li>
                <li>Sin Acta por paquete entregado sin Bolsa PREP.</li>
              </ol>

              <p class="font-weight-bold mb-0 small">
                Todos los campos son ilegibles
              </p>
              <p class="small">
                Todos los campos del Acta PREP correspondientes al número de votos,
                son ilegibles tanto en letra como en número.
              </p>

              <p class="font-weight-bold mb-0 small">
                Todos los campos vacíos
              </p>
              <p class="small">
                Todos los campos del Acta PREP correspondientes al número de votos,
                se encuentran vacíos tanto en letra como en número.
              </p>
            </div>

            <div v-if="anchoPantalla < 992">
              <p class="titulo titulo-tres">
                <b>
                  No identificadas - <small>Fuera del catálogo</small>
                </b>
              </p>

              <p class="small">
                No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas
                aprobadas por el INE, al presentar alguna comisión, ilegibilidad o error en
                sus datos de identificación. Para fines de publicación, el Acta se muestra al
                final del listado de Actas aprobadas en la Base de Datos.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <p>
          Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto,
          no tienen efectos jurídicos. 
        </p>
      </div>

    </Cuerpo>
    <pie-de-pagina />

    <detalle-eleccion-candidaturas
      :resumen="resumenDistrito"
      :votos="votos"
      :esXDistrito="true"
    />
    <compartir />
  </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo';
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';
import DetalleEleccionCandidaturas from '@/components/DetalleEleccionCandidaturas.vue';
import Compartir from '@/components/Compartir.vue';
import EstadisticasInternas from '@/components/Escritorio/componentes/EstadisticasInternas.vue';
import PieDePagina from '../../componentes/PieDePagina.vue';
import SliderVotosVA from '@/components/Escritorio/componentes/SliderVotosVA.vue';
import ProcedenciaActas from '@/components/ProcedenciaActas.vue';
import SliderVotos from '@/components/Escritorio/componentes/SliderVotos.vue';

export default {
  name: 'VotoAnticipadoEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    DetalleEleccionCandidaturas,
    EstadisticasInternas,
    SliderVotosVA,
    ProcedenciaActas,
    Compartir,
    SliderVotos,
    PieDePagina,
  },
  data() {
    return {
      random: 0,
      votos: {},
      cc1:require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png') ,
      cc2:require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg') ,
      mc:require('@/assets/emblemas/MC.png') ,
      fxm:require('@/assets/emblemas/FXMBCS.png') ,
      ml:require('@/assets/emblemas/ML.png') ,
      pes:require('@/assets/emblemas/PESBCS.png') ,
      //ACTAS DE VOTO ANTICIPADO EN DIPUTACIONES
      DD1:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/e93d006ea563066f2bdbe0ccb99064d7dbd26eae838a6b946b7c580be2a4ceb2.jpeg',
      DD2:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/6a6c5326bd1f2b921b064848ff7ed12f7233276096a95e61ef202e3a8921fbd7.jpeg' ,
      DD3:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/604a7d900dd8ef596654a0255472188a0e77f41ea3a09c65ad364dcd502218cf.jpeg' ,
      DD4:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/802a9e5c0e51f1fca88305548c54fc9b8e227cc37e813698985a477be3252660.jpeg' ,
      DD5:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/16dcab15166eacb9230394b0fdf2d757e39667288438d100cef112c1e3f14ba5.jpeg' ,
      DD6:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/f6e52da43c5b0d19281c417d8c563035676ed3c9e98cdf8e52b6d7665f39033f.jpeg' ,
      DD7:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/1b73e069e14273d6e66cdf502a8299dc793a1e73690e27fc88b58562df877a46.jpeg' ,
      DD11:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/f6d69b642878a31990790b2bd60812172ddf53b373a9db0617fb558ad7365b6a.jpeg' ,
      DD12:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/5fe6e3d82fe65e551dec10deb585bbfdb87df83995fff96263a5a2153fb55b79.jpeg' ,
      DD14:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/7b8ee5aa1f07a01f955b720c4559490c37027517b01859e1e9fdc6f22350cd81.jpeg' ,
      DD15:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/b4d94b74f9a807d056bd7a1e0dcf976e59bab906fbd124ffe340ba7d89f97cd4.jpeg' ,
      DD16:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/b0654029f0c07110b5e49ae38f6316e003e92118f60d479338a4e5ea1eca182e.jpeg' ,
      //ACTAS DE VOTO ANTICIPADO EN MULEGUE 
      M2:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/617257198b9aba75235f992f0373af7609d83fc01ea1ffb6c3ff8c0237356aa4.jpeg' ,
      //ACTAS DE VOTO ANTICIPADO EN AYUNTAMIENTOS EN LA PAZ
      M3A1:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/41c6e413a697414a0429329cc1a989d265fc0168afd86d0e335db0404465af2a.jpeg',
      M3A2:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/bdba090e9074ac54d2910d94d184241836b1a0517b7b254ba4f8c4f2e138b9d2.jpeg',
      M3A3:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/c0ee4b3380c02e58516edc7362b2851004557d86aff71af4db10fba435f2d5bf.jpeg',
      M3A4:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/db654a803542e844ba5d7ef2c9917dc714be4f85ca517bfe2daac8bf15eca805.jpeg',
      M3A5:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/36416a80524514c73bdc358c6d1d3a9cc451802bcb9ba1051566616ec69cad35.jpeg',
      M3A6:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/584a5e3a92709a8372382226c9f35df1841cfcbe4e57abc4e8583e88a8fe1617.jpeg',
      //ACTAS DE VOTO ANTIICPADO EN AYUNTAMIENTOS LOS CABOS
      M4A1:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/2976b5770e21cef6672507ee15cc6593f5ca9322a7942b132471893b1b129b13.jpeg',
      M4A2:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/9a80ef900f58ac2d65aa8f2fda4b40580ac19a350c1309b3e85b994eb0c1ecaa.jpeg',
      M4A3:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/69ef992bcfbf9f6e3dd28f1dc307f3250af7b71ffbd34ff8c8af21e6ba50e006.jpeg',
      M4A4:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/486ab4c85a9bb56cd531defee8b170f1ede46895f8a9caa5c94e8a3a611109b2.jpeg',
      M4A5:'https://prep-test-2023.s3.us-east-1.amazonaws.com/actas/f22ff95c8aa97713117c9bd8a0e42b064a9fc58b63c7fc3b975a3962c663e188.jpeg',
      
      //ARREGLO X DISTRITOS
      DC1:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC2:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},        
      ],
      DC3:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC4:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC5:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
      ],
      DC6:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC7:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],     
      DC11:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC12:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC14:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
      ],
      DC15:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
        {src: require('@/assets/emblemas/PESBCS.png'), alt: 'PARTIDO ENCUENTRO SOCIAL'},
      ],
      DC16:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
      ],
      //ARREGLO X MUNICIPIOS
      MC2:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        
      ],
      MC3:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
      ],
      MC4:[
        {src: require('@/assets/emblemas/PAN-PRI-PRD-PRS-PHBCS.png'), alt: 'JUNTOS POR BCS'},
        {src: require('@/assets/emblemas/PT-PVEM-MORENA-NABCS.jpeg'), alt: 'SIGAMOS HACIENDO HISTORIA EN BAJA CALIFORNIA SUR'},
        {src: require('@/assets/emblemas/MC.png'), alt: 'MOVIMIENTO CIUDADANO'},
        {src: require('@/assets/emblemas/FXMBCS.png'), alt: 'FUERZA POR MEXICO'},
        {src: require('@/assets/emblemas/ML.png'), alt: 'MOVIMIENTO LABORISTA'},
      ],
      
    };
  },  
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    listaDistritos() {
      return this.$route.params.distrito ;
    },
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }
      return '';
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    distrito() {
      return this.$route.params.distrito; 
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(d => d.id == this.distrito).descripcion;
    },
    // estadisticas() {
    //   if (this.$route.params.eleccion === 'G') {
    //     return this.$store.state.gEstadisticasNivelDistrito.distritos[this.$route.params.distrito - 1];
    //   } if (this.$route.params.eleccion === 'D') {
    //     return this.$store.state.dEstadisticasNivelDistrito.distritos[this.$route.params.distrito - 1];
    //   } if (this.$route.params.eleccion === 'A') {
    //     return this.$store.state.aEstadisticasNivelDistrito.municipios[this.$route.params.distrito - 1];
    //   }
    //   return {};
    // },
    votosXEstadistica() {
     if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosMesaVA.distritos[this.$route.params.distrito - 1];
      } 
      if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosMesaVA.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    votosXCandidatoDistritoTarjetaVA() {
      const { eleccion } = this.$route.params;

      if (eleccion === 'D') {
        return this.$store.state.dVotosXVA.distritos[this.$route.params.distrito - 1];
      } if (eleccion === 'A') {
        return this.$store.state.aVotosXVA.municipios[this.$route.params.distrito - 1];
      }
      return null;
    },
    colores() {
      return obtenerColoresDeAvatar(this);
    },
    resumenDistrito() {
     
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenXVA.distritos[this.$route.params.distrito - 1];
      }
       if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenXVA.distritos[this.$route.params.distrito - 1];
      }
      // return {};
    },
    
   
    distribucion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gDistribucionVotosCandidaturasDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dDistribucionVotosCandidaturasDistrito.distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aDistribucionVotosCandidaturasDistrito.municipios[this.$route.params.distrito - 1];
      }
      return {};
    },
    // votosXCasillaColumnas(){
    //       if (eleccion === 'G') {
    //         return state.value.gVotosXCasilla
    //           .datos_distritos[distrito.value - 1];
    //       }

    //       if (eleccion === 'D') {
    //         return state.value.dVotosXCasillaVA
    //           .datos_distritos[distrito.value - 1];
    //       }

    //       if (eleccion === 'A') {
    //         return state.value.aVotosXCasillaVA
    //           .datos_municipios[distrito.value - 1];
    //       }

    //   return {};
    // },
  },
 
  methods: {
    onCheckboxChange() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    obtenerAlt(ruta) {
      return ruta.replace('/emblemas/', '')
        .replace('.jpeg', '');
    },
    setearVotos() {
      const niveles = {
        G: 'gVotosXCandidatoDistritoTarjeta',
        A: 'aVotosXCandidatoDistritoTarjeta',
        D: 'dVotosXCandidatoDistritoTarjeta',
      };

      const { distrito, eleccion } = this.$route.params;

      const estado = this.$store.state[
        niveles[eleccion]
      ];

      if (eleccion === 'A') {
        this.votos = estado?.municipios[distrito - 1];
      } else {
        this.votos = estado?.distritos[distrito - 1];
      }
    },
  },
  created() {
    this.setearVotos();
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setearVotos();
      },
    },
  },
 
};
</script>

<style lang="scss" scoped>
table{
  width: 100%;
}
.rowFlex {
  display: flex;
  flex-flow: nowrap;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  scroll-behavior: smooth; 
}
.contenedor-padre {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
  gap: 15px;

  .btn-fijo {
    background: transparent;
    border: none;
    color: #707070;
    z-index: 1;
    transition: all ease .2s;

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }
}
#tooltip2 {
  position: relative;
  display: inline-block;

  .extranjero {
    margin-bottom: 40px;
  }

  .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    // margin: 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;

    font-size: smaller;

    bottom: 100%;
    left: 50%;
    margin-left: -125px;
    opacity: 0.85;

    margin-bottom: 5px;

    &::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  &:hover{
    .tooltiptext{
      visibility: visible;
    }
  }
}
.table-responsive {
  thead {
    th {
      background-color:#582D73;
      color: #FFF;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
          /* max-width: 35px; */
        }
      }
    }
  }
  tbody {
    tr {
      
      td {
        
        vertical-align: middle;
        img {
          max-height: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}
// .table-responsive2 {
//   width: 100%; 
//   table-layout: auto;
//   thead {
//     th {
//       background-color:#F4F4F4;
//       border:1px solid #DEDEDE ;
//       color: #000000;

//       th {
//         vertical-align: middle;
//         img {
//           max-height: 35px;
//           /* max-width: 35px; */
//         }
//       }
//     }
//   }
 
  
// }
.table-responsive2 {
  width: 100%; 
  table-layout: auto;
 
  thead th {
    background-color: #582D73;
    border: none;
    color: #ffffff;
    vertical-align: middle;
    // padding: 10px; 
    // height: 5px;
    // width: 100px;
  }
  td {
  min-width: 100px; 
  // max-width: 120%; 
  // word-wrap: break-word; 
}

}
.table-responsive4 {
  // width: 100%; 
  table-layout: auto;
 
  .tabla{
    thead 
    th {
        width: 10%;
        background-color: #F4F4F4;
        border: 1px solid #DEDEDE;
        color: #000000;
        vertical-align: middle;
        padding: 5px; 
        // height: 10px;
      }
      
      td {
    
      min-width: 100%;       
      word-wrap: break-word;
      border-top: none;
      border-bottom: none; 
    }

  }

  .table-striped tbody tr:nth-of-type(odd) {
      background-color: #fafafa; /* Color claro para filas impares */
      
    }

  .table-striped tbody tr:nth-of-type(even) {
      background-color: #F2EFEF; /* Color oscuro para filas pares */
      
  }

}


.table-responsive3 {
  width: 100%; 
  table-layout: auto;
  .tabla{
    width: 95%;
  }
  thead th {
    background-color: #582D73;
    // width: 5px;
    border: none;
    color: #ffffff;
    vertical-align: middle;
    padding-left: 5px; 
    padding-right: 5px; 
    padding-top: 0px;
    padding-bottom: 0px;
    // height: 10px;
  }
  td, tr{
  min-width: 100%; 
  border: none;
 
  
}

}

.titulos {
  h3 {
     font-size: 26px;
     color: black;
     font-weight: bold;
     border-left: 15px solid #582D73;
     padding-left: 10px;
  }
}
.ObservacionesActas {
  background-color: #ffffff;
  border-radius: 8px;
    // padding: 10px 20px 20px 10px;
    margin-bottom: 25px;
  // @media (min-width: 992px) {
  //   background-color: #fff;
  //   margin: 0px;
  // }
}
.titulo-uno {
  border-color: #d2127e;
  color: #d2127e
}

.titulo-dos {
  border-color: #4199a0;
  color: #4199a0;
}

.titulo-tres {
  border-color: #7a4493;
  color: #7a4493;
  small {
    color: black;
    font-weight: bold;
  }
}

ol {
  margin-left: 30px;
}

.grilla {
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: flex-start;
    gap: 30px;
    max-width: 1000px;
    margin: 0 auto;
  }
}
.resumen-voto-candidatura {
  min-height: 90%;
  display: grid;
  grid-template-rows: 1fr;
  // padding: 3px;
  border: 1px solid #582D73;
  //height: 25vh;
  max-width: 160px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top:10px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
 

  .total {
    p > span {
      color: #D2127E;
    }
  }
}
.noGanador{
  border-color: #000000;

}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #582D73;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#582D73, 10%);
}

.resumen-votacion {
  // background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #000000;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #582D73;
      }
    }
  }
}

.tabla-distribucion-votos {
  tbody {
    tr {
      vertical-align: top;
      td {
        svg {
          max-width: 50px;
        }

        &:nth-child(2n) {
          width: 50%;
        }
      }
      .voto {
        text-align: center;
        background: transparent;
        p {
          font-size: 1.5rem !important;
          font-weight: bold !important;
        }
        .titulo{
          font-size: 1rem !important;
          font-weight: bold !important;
        }
        svg{
            background-color: black;
            color: white;
            padding: 5px;
            border-radius: 50px;
            width: 20px;
            font-size: 20px;
        }
      }
      .votos {
        text-align: center;
        background: transparent;
        p {
          font-size: 1.5rem !important;
          font-weight: bold !important;
        }
        .titulo{
          font-size: 1rem !important;
          font-weight: bold !important;
        }
        svg{
            background-color: black;
            color: white;
            padding: 5px;
            border-radius: 50px;
            width: 20px;
            font-size: 20px;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #582D73;
  border-color: transparent;
}

</style>
